import {
  GET_FORUMS,
  GET_FORUM_GENDER_RATIO,
  GET_FORUM_HOT_POSTS,
  GET_FORUM_INFO,
  GET_FORUM_POSTS,
  GET_FORUM_SCHOOLS,
  GET_FORUM_SUBSCRIPTION_COUNTS,
  GET_FORUM_TOPICS,
  GET_FORUM_TRENDS,
} from '../../types';
import {
  getForumGenderRatioPromise,
  getForumHotPostsPromise,
  getForumInfoPromise,
  getForumPostsPromise,
  getForumSchoolsPromise,
  getForumSubscriptionCountsPromise,
  getForumTopicsPromise,
  getForumTrendsPromise,
  getForumsPromise,
} from './promises';
import {
  getDaysAgoDateString,
} from '../../components/Utils';

export const getForums = () => ({
  type: GET_FORUMS,
  payload: getForumsPromise(),
});

export const getForumTopics = (id, limit) => ({
  type: GET_FORUM_TOPICS,
  payload: getForumTopicsPromise(id, limit),
});

export const getForumSchools = (id, limit) => ({
  type: GET_FORUM_SCHOOLS,
  payload: getForumSchoolsPromise(id, limit),
});

export const getForumInfo = (id) => ({
  type: GET_FORUM_INFO,
  payload: getForumInfoPromise(id),
});

export const getForumGenderRatio = (id) => ({
  type: GET_FORUM_GENDER_RATIO,
  payload: getForumGenderRatioPromise(id),
});

export const getForumTrends = (id) => ({
  type: GET_FORUM_TRENDS,
  payload: getForumTrendsPromise(id),
});

export const getForumSubscriptionCounts = (id) => ({
  type: GET_FORUM_SUBSCRIPTION_COUNTS,
  payload: getForumSubscriptionCountsPromise(id),
});

export const getForumHotPosts = (id, sinceDaysAgo, limit, offset) => ({
  type: GET_FORUM_HOT_POSTS,
  payload: getForumHotPostsPromise(
    id,
    getDaysAgoDateString(sinceDaysAgo),
    limit,
    offset,
  ),
  meta: {
    forum: id,
    sinceDaysAgo,
    limit,
    offset,
  },
});

export const getForumPosts = (id, limit, offset) => ({
  type: GET_FORUM_POSTS,
  payload: getForumPostsPromise(id, limit, offset),
  meta: {
    forum: id,
    limit,
    offset,
  },
});
