import {
  FORGOT_PASSWORD,
  GET_ME,
  LOGIN_USER,
  LOGOUT_USER,
  SIGNUP_USER,
} from '../types';
import {
  forgotPasswordPromise,
  getMePromise,
  loginUserPromise,
  logoutUserPromise,
  signupUserPromise,
} from './promises';
import firebase, { providers } from '../Firebase';

export const getMe = () => ({
  type: GET_ME,
  payload: getMePromise(),
});

export const loginUser = ({ email, password }) => ({
  type: LOGIN_USER,
  payload: loginUserPromise(email, password),
});

export const signupUser = ({ email, password, nickname }) => ({
  type: SIGNUP_USER,
  payload: signupUserPromise(email, password, nickname),
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: logoutUserPromise(),
});

export const forgotPassword = ({ email }) => ({
  type: FORGOT_PASSWORD,
  payload: forgotPasswordPromise(email),
});

export const authUserUsingGoogle = isMobile => ({
  type: LOGIN_USER,
  payload: isMobile
    ? firebase.auth().signInWithRedirect(providers.google)
    : firebase.auth().signInWithPopup(providers.google),
});

export const authUserUsingFacebook = isMobile => ({
  type: LOGIN_USER,
  payload: isMobile
    ? firebase.auth().signInWithRedirect(providers.facebook)
    : firebase.auth().signInWithPopup(providers.facebook),
});
