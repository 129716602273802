import {
  SET_DOCUMENT_TITLE,
  SET_META_TAGS,
} from '../types';

export const setDocumentTitle = (title) => ({
  type: SET_DOCUMENT_TITLE,
  payload: title,
});

export const setMetaTags = (tags) => ({
  type: SET_META_TAGS,
  payload: tags,
});
