import axios from 'axios';
import ms from 'ms';
import lodashGet from 'lodash/get';

import firebase from '../Firebase';
import tokenProvider from '../libs/axios-token-interceptor';
import {
  TOKEN_CACHE_TTL,
  API_REQUEST_PAYLOAD_TOP_LEVEL_KEY,
} from '../constants';
import { showMessage } from '../components/Utils';

const baseURL = `${
  process.env.NODE_ENV === 'production'
    ? 'https://pttbrain-api.herokuapp.com'
    : ''
}/api`;

// `transformResponse` allows changes to the response data to be made before
// it is passed to then/catch
const transformResponse = [
  response => {
    // Do whatever you want to transform the data
    try {
      const payload = JSON.parse(response);
      if (API_REQUEST_PAYLOAD_TOP_LEVEL_KEY.FAILURE in payload) {
        return payload;
      }
      return payload[API_REQUEST_PAYLOAD_TOP_LEVEL_KEY.SUCCESS];
    } catch (e) {
      console.error(e);
      return response;
    }
  },
];

const apiPublicRequestInstance = axios.create({
  baseURL,
  transformResponse,
});

const apiRequestInstance = axios.create({
  baseURL,
  transformResponse,
});

// Auth Interceptor
const tokenCache = tokenProvider.tokenCache(
  async () => {
    let token = null;

    try {
      token = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);
      return token;
    } catch (e) {
      return token;
    }
  },
  {
    maxAge: ms(TOKEN_CACHE_TTL),
  },
);

apiRequestInstance.interceptors.request.use(
  tokenProvider({
    getToken: tokenCache,
  }),
);

// Error Handling
const errorResponseHandler = error => {
  if (
    Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  // if has response show the error
  if (error.response) {
    const { status, data } = error.response;
    const errorMessage = lodashGet(
      data,
      API_REQUEST_PAYLOAD_TOP_LEVEL_KEY.FAILURE,
    );
    switch (status) {
      case 401:
        showMessage.error('並沒有權限造訪此頁面');
        break;
      case 429:
        showMessage.error('此IP目前被暫時阻擋requests');
        break;
      case 500:
        showMessage.error('Opps...系統似乎有問題, 我們會盡早解決並恢復服務');
        break;
      case 503:
        showMessage.error('系統正在維護中，請過幾小時後再回來造訪喔!!');
        break;
      default:
        if (errorMessage) {
          showMessage.error(errorMessage);
        }
        break;
    }
  }
  return Promise.reject(error);
};

apiPublicRequestInstance.interceptors.response.use(
  response => response,
  errorResponseHandler,
);

apiRequestInstance.interceptors.response.use(
  response => response,
  errorResponseHandler,
);

export { apiPublicRequestInstance, apiRequestInstance };
