import {
  GET_SCHOOL_HOT_POSTS,
  GET_SCHOOL_INFO,
  GET_SCHOOL_INTERESTS,
  GET_SCHOOL_POSTS,
  GET_SCHOOL_RECENT_ACTIVITIES,
  GET_SCHOOL_TOPICS,
} from '../../types';
import {
  getSchoolHotPostsPromise,
  getSchoolInfoPromise,
  getSchoolInterestsPromise,
  getSchoolPostsPromise,
  getSchoolRecentActivitiesPromise,
  getSchoolTopicsPromise,
} from './promises';
import {
  getDaysAgoDateString,
} from '../../components/Utils';

export const getSchoolInfo = (id) => ({
  type: GET_SCHOOL_INFO,
  payload: getSchoolInfoPromise(id),
});

export const getSchoolHotPosts = (id, sinceDaysAgo, limit, offset) => ({
  type: GET_SCHOOL_HOT_POSTS,
  payload: getSchoolHotPostsPromise(
    id,
    getDaysAgoDateString(sinceDaysAgo),
    limit,
    offset,
  ),
  meta: {
    school: id,
    sinceDaysAgo,
    limit,
    offset,
  },
});

export const getSchoolPosts = (id, limit, offset) => ({
  type: GET_SCHOOL_POSTS,
  payload: getSchoolPostsPromise(id, limit, offset),
  meta: {
    school: id,
    limit,
    offset,
  },
});

export const getSchoolInterests = (id) => ({
  type: GET_SCHOOL_INTERESTS,
  payload: getSchoolInterestsPromise(id),
});

export const getSchoolTopics = (id, limit) => ({
  type: GET_SCHOOL_TOPICS,
  payload: getSchoolTopicsPromise(id, limit),
});

export const getSchoolRecentActivities = (id) => ({
  type: GET_SCHOOL_RECENT_ACTIVITIES,
  payload: getSchoolRecentActivitiesPromise(id),
});
