import {
  GET_PERSONA_DAILY_ACTIVITIES_DETAILS,
  GET_PERSONA_INFO,
  GET_PERSONA_INTERESTS,
  GET_PERSONA_POSTS,
  GET_PERSONA_RECENT_ACTIVITIES,
} from '../../types';

import {
  getPersonaDailyActivitiesDetailsPromise,
  getPersonaInfoPromise,
  getPersonaInterestsPromise,
  getPersonaPostsPromise,
  getPersonaRecentActivitiesPromise,
} from './promises';

export const getPersonaInfo = (id) => ({
  type: GET_PERSONA_INFO,
  payload: getPersonaInfoPromise(id),
});

export const getPersonaRecentActivities = (id) => ({
  type: GET_PERSONA_RECENT_ACTIVITIES,
  payload: getPersonaRecentActivitiesPromise(id),
});

export const getPersonaPosts = (id, limit, offset) => ({
  type: GET_PERSONA_POSTS,
  payload: getPersonaPostsPromise(id, limit, offset),
  meta: {
    persona: id,
    limit,
    offset,
  },
});

export const getPersonaInterests = (id) => ({
  type: GET_PERSONA_INTERESTS,
  payload: getPersonaInterestsPromise(id),
});

export const getPersonaDailyActivitiesDetails = (id, at) => ({
  type: GET_PERSONA_DAILY_ACTIVITIES_DETAILS,
  payload: getPersonaDailyActivitiesDetailsPromise(id, at),
});
