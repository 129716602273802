// Root
export const ALERT_STACK_LIMIT = 1;
export const DEFAULT_DAYS_AGO = 360;
export const SEARCH_ALL_LIMIT = 10;

// PTT Pages
export const ARTICLE_PUSHES_LIMIT = 50;
export const BOARD_ARTICLES_LIMIT = 5;
export const USER_ARTICLES_LIMIT = 5;
export const USER_FANS_LIMIT = 15;

// Dcard Pages
export const FORUM_POSTS_LIMIT = 5;
export const FORUM_TOPICS_LIMIT = 25;
export const PERSONA_POSTS_LIMIT = 5;
export const POST_COMMENTS_LIMIT = 50;
export const SCHOOL_POSTS_LIMIT = 5;
export const SCHOOL_TOPICS_LIMIT = 25;

// Others
export const TOKEN_CACHE_TTL = '30m';
export const A_MONTH_AGO_IN_DAYS = 28;
export const A_WEEK_AGO_IN_DAYS = 7;
export const MESSAGE_TIMEOUT = {
  ERROR: 5000,
  INFO: 5000,
  SUCCESS: 10000,
};
export const ROUTE_PATH = {
  INDEX_HTML: '/index.html',

  ABOUT_US: '/about_us',
  CAREERS: '/careers',
  FAQ: '/faq',
  FORGOT_PASSWORD: '/forgot_password',
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MY_FAVORITES: '/my-favorites',
  PRIVACY_POLICY: '/privacy-policy',
  SEARCH: '/search',
  SIGNUP: '/signup',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',

  // PTT
  PTT_HOME: '/ptt',
  PTT_BOARD: '/ptt/board/:id',
  PTT_ARTICLE: '/ptt/article/:id',
  PTT_USER: '/ptt/user/:id',

  // Dcard
  DCARD_HOME: '/dcard',
  DCARD_PERSONA: '/dcard/persona/:id',
  DCARD_FORUM: '/dcard/forum/:id',
  DCARD_POST: '/dcard/post/:id',
  DCARD_SCHOOL: '/dcard/school/:id',
};
export const API_REQUEST_PAYLOAD_TOP_LEVEL_KEY = {
  SUCCESS: 'data',
  FAILURE: 'error',
};
