import {
  GET_DASHBOARD_ALL_FOLLOWINGS,
} from '../types';
import {
  getDashboardAllFollowingsPromise,
} from './promises';

export const getAllFollowings = (platform) => ({
  type: GET_DASHBOARD_ALL_FOLLOWINGS,
  payload: getDashboardAllFollowingsPromise(platform),
});
