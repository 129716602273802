import {
  GET_POST_COMMENTS,
  GET_POST_INFO,
} from '../../types';

import {
  getPostCommentsPromise,
  getPostInfoPromise,
} from './promises';

export const getPostInfo = (id) => ({
  type: GET_POST_INFO,
  payload: getPostInfoPromise(id),
});

export const getPostComments = (id, limit, offset) => ({
  type: GET_POST_COMMENTS,
  payload: getPostCommentsPromise(id, limit, offset),
  meta: {
    post: id,
    limit,
    offset,
  },
});
