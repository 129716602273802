import firebase from '../Firebase';
import { apiPublicRequestInstance, apiRequestInstance } from './utils';

const MESSAGES = {
  SIGNUP_SUCCESSFULLY: 'Sing Up Successfully !! Please Verify Your Email.',
  LOGOUT_SUCCESSFULLY: 'Log Out Successfully !! See You Soon.',
  VERIFICATION_EMAIL_WAS_SENT:
    'Verification Email Was Sent. Please Check Your Mailbox.',
  EMAIL_IS_NOT_VERIFIED: 'Email Is Not Verified.',
};

/*
  Auth requests
*/
export const getMePromise = async () => apiRequestInstance.get('/me');

export const loginUserPromise = async (email, password) => {
  const { user } = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  if (user) {
    if (!user.emailVerified) {
      // Send the email again
      await user.sendEmailVerification();
      await firebase.auth().signOut();
      throw new Error(MESSAGES.EMAIL_IS_NOT_VERIFIED);
    }
  }
  return user;
};

export const signupUserPromise = async (email, password, nickname) => {
  await firebase.auth().createUserWithEmailAndPassword(email, password);
  const user = firebase.auth().currentUser;
  await user.updateProfile({
    displayName: nickname,
  });
  await user.sendEmailVerification();
  await firebase.auth().signOut();
  return {
    message: MESSAGES.SIGNUP_SUCCESSFULLY,
  };
};

export const logoutUserPromise = async () => {
  await firebase.auth().signOut();
  return {
    message: MESSAGES.LOGOUT_SUCCESSFULLY,
  };
};

export const forgotPasswordPromise = async email => {
  await firebase.auth().sendPasswordResetEmail(email);
  return {
    message: MESSAGES.VERIFICATION_EMAIL_WAS_SENT,
  };
};

/*
  Search requests
*/
export const getAllSearchResultsPromise = (platform, q, limit, offset) =>
  apiPublicRequestInstance.get('/search', {
    params: {
      platform,
      q,
      limit,
      offset,
    },
  });

/*
  Following requests
*/
export const getFollowingPromise = (platform, targetType, targetId) =>
  apiRequestInstance.get(`/following/${platform}/${targetType}/${targetId}`);
export const addFollowingPromise = (platform, targetType, targetId) =>
  apiRequestInstance.post(
    `/ptt/following/${platform}/${targetType}/${targetId}`,
  );
export const removeFollowingPromise = (platform, targetType, targetId) =>
  apiRequestInstance.delete(
    `/ptt/following/${platform}/${targetType}/${targetId}`,
  );

/*
  Dashboard requests
*/
export const getDashboardAllFollowingsPromise = platform =>
  apiRequestInstance.get(`/dashboard/${platform}`);

/*
  Dcard Metric Requests
*/
export const getDcardDBStatsPromise = () =>
  apiRequestInstance.get('/dcard/metric/db-stats');
export const getDcardHotPostsPromise = (since, limit, offset) =>
  apiRequestInstance.get('/dcard/metric/hot-posts', {
    params: {
      since,
      limit,
      offset,
    },
  });

/*
  PTT Metric Requests
*/
export const getPttDBStatsPromise = () =>
  apiPublicRequestInstance.get('/ptt/metric/db-stats');
export const getAvgPopularTimePromise = () =>
  apiPublicRequestInstance.get('/ptt/metric/popular-time');
export const getHotArticlesPromise = (since, limit, offset) =>
  apiPublicRequestInstance.get('/ptt/metric/hot-articles', {
    params: {
      since,
      limit,
      offset,
    },
  });
export const getUserGeoDistributionsPromise = () =>
  apiPublicRequestInstance.get('/ptt/metric/user-geo-distributions');
