import {
  GET_BOARDS,
  GET_BOARD_ARTICLES,
  GET_BOARD_HOT_ARTICLES,
  GET_BOARD_INFO,
  GET_BOARD_TOP_USERS,
  GET_BOARD_TRENDS,
  GET_BOARD_USER_COUNT,
} from '../../types';
import {
  getBoardArticlesPromise,
  getBoardHotArticlesPromise,
  getBoardInfoPromise,
  getBoardTopUsersPromise,
  getBoardTrendsPromise,
  getBoardUserCountPromise,
  getBoardsPromise,
} from './promises';
import {
  getDaysAgoDateString,
} from '../../components/Utils';

export const getBoards = () => ({
  type: GET_BOARDS,
  payload: getBoardsPromise(),
});

export const getBoardInfo = (id) => ({
  type: GET_BOARD_INFO,
  payload: getBoardInfoPromise(id),
});

export const getBoardTrends = (id) => ({
  type: GET_BOARD_TRENDS,
  payload: getBoardTrendsPromise(id),
});

export const getBoardUserCount = (id) => ({
  type: GET_BOARD_USER_COUNT,
  payload: getBoardUserCountPromise(id),
});

export const getBoardHotArticles = (id, sinceDaysAgo, limit, offset) => ({
  type: GET_BOARD_HOT_ARTICLES,
  payload: getBoardHotArticlesPromise(
    id,
    getDaysAgoDateString(sinceDaysAgo),
    limit,
    offset,
  ),
  meta: {
    board: id,
    sinceDaysAgo,
    limit,
    offset,
  },
});

export const getBoardArticles = (id, limit, offset) => ({
  type: GET_BOARD_ARTICLES,
  payload: getBoardArticlesPromise(id, limit, offset),
  meta: {
    board: id,
    limit,
    offset,
  },
});

export const getBoardTopUsers = (id) => ({
  type: GET_BOARD_TOP_USERS,
  payload: getBoardTopUsersPromise(id),
});
