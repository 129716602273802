import React from 'react';
import chain from 'lodash/chain';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { MESSAGE_TIMEOUT } from '../constants';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const getDaysAgoDateString = d =>
  dayjs
    .utc()
    .add(Math.round(d * 24) * -1, 'hour')
    .format();

export const getUserAvatar = userId =>
  `https://source.boringavatars.com/beam/120/${userId}`;

export const getUrls = text => text.match(/(https?:\/\/[^\s]+)/g) || [];

export const checkImageURL = url => url.match(/\.(jpeg|jpg|gif|png)$/) != null;

export const parseImgurURL = url => {
  const { origin, protocol, pathname } = new URL(url);
  if (origin === 'https://imgur.com' && protocol === 'https:') {
    return `https://i.imgur.com${pathname}.jpg`;
  }
  return url;
};

export const formatDatetime = d => dayjs.utc(d).format('YYYY-MM-DD');

export const formatTimestamp = t => dayjs.utc(t).format('llll');

export const formatTimestampWithTimezone = t => dayjs(t).format('llll');

export const formatNumber = num => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(1).replace(rx, '$1') + si[i].symbol;
};

export const getPushIcon = (tag, size) => {
  if (tag.includes('推')) {
    return <Icon name="thumbs outline up" color="blue" size={size} />;
  }
  if (tag.includes('噓')) {
    return <Icon name="thumbs outline down" color="red" size={size} />;
  }
  if (tag.includes('→')) {
    return <Icon name="arrow right" color="yellow" size={size} />;
  }
  return <Icon name="question" />;
};

export const groupByKey = (data, key) => chain(data).groupBy(key).value();

export const sortByDate =
  (order = 1) =>
  (a, b) =>
    order > 0 ? new Date(b) - new Date(a) : new Date(a) - new Date(b);

export const showMessage = {
  info: text =>
    toast(text, {
      position: 'bottom-right',
      type: 'info',
    }),
  warn: text =>
    toast(text, {
      position: 'bottom-right',
      type: 'warning',
    }),
  success: text =>
    toast(text, {
      position: 'bottom-right',
      type: 'success',
    }),
  error: text =>
    toast(text, {
      position: 'bottom-right',
      type: 'error',
    }),
};

export const isNumeric = n =>
  !Number.isNaN(parseFloat(n)) && Number.isFinite(Number(n));

export const truncString = (str, n) =>
  str.length > n ? `${str.substr(0, n - 1)}...` : str;

export const TwentyFourHourToTwelveHourFormat = h => {
  if (!isNumeric(h)) {
    return 'N/A';
  }

  const hourInNumber = parseInt(h, 10);

  if (hourInNumber < 0 || hourInNumber > 23) {
    return 'N/A';
  }

  if (hourInNumber / 12 === 1) {
    return '12pm';
  }

  if (hourInNumber === 0) {
    return '12am';
  }

  return hourInNumber > 12 ? `${hourInNumber - 12}pm` : `${hourInNumber}am`;
};

export const encodeHTML = s =>
  s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
