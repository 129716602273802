import {
  GET_ARTICLE_INFO,
  GET_ARTICLE_PUSHES,
  GET_ARTICLE_PUSHES_STATS,
  GET_ARTICLE_REPLIES,
} from '../../types';
import {
  getArticleInfoPromise,
  getArticlePushesPromise,
  getArticlePushesStatsPromise,
  getArticleRepliesPromise,
} from './promises';

export const getArticleInfo = (id) => ({
  type: GET_ARTICLE_INFO,
  payload: getArticleInfoPromise(id),
});

export const getArticlePushes = (id, limit, offset) => ({
  type: GET_ARTICLE_PUSHES,
  payload: getArticlePushesPromise(id, limit, offset),
  meta: {
    article: id,
    limit,
    offset,
  },
});

export const getArticlePushesStats = (id) => ({
  type: GET_ARTICLE_PUSHES_STATS,
  payload: getArticlePushesStatsPromise(id),
});

export const getArticleReplies = (id) => ({
  type: GET_ARTICLE_REPLIES,
  payload: getArticleRepliesPromise(id),
});
