import dayjs from 'dayjs';

import {
  A_MONTH_AGO_IN_DAYS,
  A_WEEK_AGO_IN_DAYS,
  DEFAULT_DAYS_AGO,
} from '../../constants';
import { apiRequestInstance } from '../utils';

/*
  Forum requests
*/
export const getForumsPromise = () => apiRequestInstance.get('/dcard/forum');
export const getForumInfoPromise = id =>
  apiRequestInstance.get(`/dcard/forum/${id}`);
export const getForumHotPostsPromise = (id, since, limit, offset) =>
  apiRequestInstance.get(`/dcard/forum/${id}/hot-posts`, {
    params: {
      since,
      limit,
      offset,
    },
  });
export const getForumPostsPromise = (id, limit, offset) =>
  apiRequestInstance.get(`/dcard/forum/${id}/posts`, {
    params: {
      limit,
      offset,
    },
  });
export const getForumTrendsPromise = id =>
  apiRequestInstance.get(`/dcard/forum/${id}/trends`, {
    params: {
      since: dayjs().subtract(A_MONTH_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
export const getForumSubscriptionCountsPromise = id =>
  apiRequestInstance.get(`/dcard/forum/${id}/subscription-counts`, {
    params: {
      since: dayjs().subtract(A_MONTH_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
export const getForumTopicsPromise = (id, limit) =>
  apiRequestInstance.get(`/dcard/forum/${id}/topics`, {
    params: {
      limit,
      since: dayjs().subtract(A_WEEK_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
export const getForumSchoolsPromise = (id, limit) =>
  apiRequestInstance.get(`/dcard/forum/${id}/schools`, {
    params: {
      limit,
      since: dayjs().subtract(A_WEEK_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
export const getForumGenderRatioPromise = id =>
  apiRequestInstance.get(`/dcard/forum/${id}/gender-ratio`, {
    params: {
      since: dayjs().subtract(A_WEEK_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });

/*
  Post requests
*/
export const getPostInfoPromise = id =>
  apiRequestInstance.get(`/dcard/post/${id}`);
export const getPostCommentsPromise = (id, limit, offset) =>
  apiRequestInstance.get(`/dcard/post/${id}/comments`, {
    params: {
      limit,
      offset,
    },
  });

/*
  Persona requests
*/
export const getPersonaInfoPromise = id =>
  apiRequestInstance.get(`/dcard/persona/${id}`);
export const getPersonaRecentActivitiesPromise = id =>
  apiRequestInstance.get(`/dcard/persona/${id}/activities`, {
    params: {
      since: dayjs().subtract(DEFAULT_DAYS_AGO, 'd').format('YYYY-MM-DD'),
    },
  });
export const getPersonaPostsPromise = (id, limit, offset) =>
  apiRequestInstance.get(`/dcard/persona/${id}/posts`, {
    params: {
      limit,
      offset,
    },
  });
export const getPersonaInterestsPromise = id =>
  apiRequestInstance.get(`/dcard/persona/${id}/interests`, {
    params: {
      since: dayjs().subtract(DEFAULT_DAYS_AGO, 'd').format('YYYY-MM-DD'),
    },
  });
export const getPersonaDailyActivitiesDetailsPromise = (id, at) =>
  apiRequestInstance.get(`/dcard/persona/${id}/dailyActivitiesDetails`, {
    params: { at },
  });

/*
  School requests
*/
export const getSchoolInfoPromise = id =>
  apiRequestInstance.get(`/dcard/school/${id}`);
export const getSchoolHotPostsPromise = (id, since, limit, offset) =>
  apiRequestInstance.get(`/dcard/school/${id}/hot-posts`, {
    params: {
      since,
      limit,
      offset,
    },
  });
export const getSchoolPostsPromise = (id, limit, offset) =>
  apiRequestInstance.get(`/dcard/school/${id}/posts`, {
    params: {
      limit,
      offset,
    },
  });
export const getSchoolInterestsPromise = id =>
  apiRequestInstance.get(`/dcard/school/${id}/interests`, {
    params: {
      since: dayjs().subtract(A_WEEK_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
export const getSchoolTopicsPromise = (id, limit) =>
  apiRequestInstance.get(`/dcard/school/${id}/topics`, {
    params: {
      limit,
      since: dayjs().subtract(A_WEEK_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
export const getSchoolRecentActivitiesPromise = id =>
  apiRequestInstance.get(`/dcard/school/${id}/activities`, {
    params: {
      since: dayjs().subtract(A_WEEK_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
