import {
  SEARCH_ALL,
} from '../types';
import {
  getAllSearchResultsPromise,
} from './promises';

export const SearchAll = (
  platform,
  q,
  limit,
  offset,
) => ({
  type: SEARCH_ALL,
  payload: getAllSearchResultsPromise(platform, q, limit, offset),
  meta: {
    platform,
    q,
    limit,
    offset,
  },
});
