import {
  GET_RELATED_USER_IDS,
  GET_USER_ARTICLES,
  GET_USER_DAILY_ACTIVITIES_DETAILS,
  GET_USER_FANS,
  GET_USER_INFO,
  GET_USER_INTERESTS,
  GET_USER_LOCATIONS,
  GET_USER_PUSHES_STATS,
  GET_USER_RECENT_ACTIVITIES,
} from '../../types';
import {
  getRelatedUserIDsPromise,
  getUserArticlesPromise,
  getUserDailyActivitiesDetailsPromise,
  getUserFansPromise,
  getUserInfoPromise,
  getUserLocationsPromise,
  getUserPushesBoardsBreakdownPromise,
  getUserPushesCategoriesBreakdownPromise,
  getUserPushesStatsPromise,
  getUserRecentActivitiesPromise,
} from './promises';

export const getUserInfo = (id) => ({
  type: GET_USER_INFO,
  payload: getUserInfoPromise(id),
});

export const getRelatedUserIDs = (id) => ({
  type: GET_RELATED_USER_IDS,
  payload: getRelatedUserIDsPromise(id),
});

export const getUserLocations = (id) => ({
  type: GET_USER_LOCATIONS,
  payload: getUserLocationsPromise(id),
});

export const getUserInterests = (id) => ({
  type: GET_USER_INTERESTS,
  payload: Promise.all([
    getUserPushesBoardsBreakdownPromise(id),
    getUserPushesCategoriesBreakdownPromise(id),
  ]),
});

export const getUserPushesStats = (id) => ({
  type: GET_USER_PUSHES_STATS,
  payload: getUserPushesStatsPromise(id),
});

export const getUserRecentActivities = (id) => ({
  type: GET_USER_RECENT_ACTIVITIES,
  payload: getUserRecentActivitiesPromise(id),
});

export const getUserDailyActivitiesDetails = (id, at) => ({
  type: GET_USER_DAILY_ACTIVITIES_DETAILS,
  payload: getUserDailyActivitiesDetailsPromise(id, at),
});

export const getUserArticles = (id, limit, offset) => ({
  type: GET_USER_ARTICLES,
  payload: getUserArticlesPromise(id, limit, offset),
  meta: {
    user: id,
    limit,
    offset,
  },
});

export const getUserFans = (id, limit) => ({
  type: GET_USER_FANS,
  payload: getUserFansPromise(id, limit),
});
