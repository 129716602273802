import {
  GET_AVG_POPULAR_TIME,
  GET_DCARD_DB_STATS,
  GET_DCARD_HOT_POSTS,
  GET_HOT_ARTICLES,
  GET_PTT_DB_STATS,
  GET_USER_GEO_DISTRIBUTIONS,
} from '../types';
import {
  getAvgPopularTimePromise,
  getDcardDBStatsPromise,
  getDcardHotPostsPromise,
  getHotArticlesPromise,
  getPttDBStatsPromise,
  getUserGeoDistributionsPromise,
} from './promises';
import {
  getDaysAgoDateString,
} from '../components/Utils';

// PTT
export const getAvgPopularTime = () => ({
  type: GET_AVG_POPULAR_TIME,
  payload: getAvgPopularTimePromise,
});

export const getHotArticles = (sinceDaysAgo, limit, offset) => ({
  type: GET_HOT_ARTICLES,
  payload: getHotArticlesPromise(
    getDaysAgoDateString(sinceDaysAgo),
    limit,
    offset,
  ),
  meta: {
    sinceDaysAgo,
    limit,
    offset,
  },
});

export const getPttDBStats = () => ({
  type: GET_PTT_DB_STATS,
  payload: getPttDBStatsPromise,
});

export const getUserGeoDistributions = () => ({
  type: GET_USER_GEO_DISTRIBUTIONS,
  payload: getUserGeoDistributionsPromise,
});

// Dcard
export const getDcardDBStats = () => ({
  type: GET_DCARD_DB_STATS,
  payload: getDcardDBStatsPromise,
});

export const getDcardHotPosts = (sinceDaysAgo, limit, offset) => ({
  type: GET_DCARD_HOT_POSTS,
  payload: getDcardHotPostsPromise(
    getDaysAgoDateString(sinceDaysAgo),
    limit,
    offset,
  ),
  meta: {
    sinceDaysAgo,
    limit,
    offset,
  },
});
