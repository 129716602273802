import dayjs from 'dayjs';

import { A_MONTH_AGO_IN_DAYS, DEFAULT_DAYS_AGO } from '../../constants';
import { apiPublicRequestInstance, apiRequestInstance } from '../utils';

/*
  Board requests
*/
export const getBoardsPromise = () => apiPublicRequestInstance
  .get('/ptt/board');
export const getBoardInfoPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/board/${id}`);
export const getBoardTrendsPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/board/${id}/trends`, {
    params: {
      since: dayjs().subtract(A_MONTH_AGO_IN_DAYS, 'd').format('YYYY-MM-DD'),
    },
  });
export const getBoardHotArticlesPromise = (id, since, limit, offset) => apiPublicRequestInstance
  .get(`/ptt/board/${id}/hot-articles`, {
    params: {
      since,
      limit,
      offset,
    },
  });
export const getBoardArticlesPromise = (id, limit, offset) => apiPublicRequestInstance
  .get(`/ptt/board/${id}/articles`, {
    params: {
      limit,
      offset,
    },
  });
export const getBoardTopUsersPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/board/${id}/top-users`);
export const getBoardUserCountPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/board/${id}/user-count`, {
    params: {
      since: dayjs().subtract(DEFAULT_DAYS_AGO, 'd').format('YYYY-MM-DD'),
    },
  });

/*
  Article requests
*/
export const getArticleInfoPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/article/${id}`);
export const getArticlePushesPromise = (id, limit, offset) => apiPublicRequestInstance
  .get(`/ptt/article/${id}/pushes`, {
    params: {
      limit,
      offset,
    },
  });
export const getArticlePushesStatsPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/article/${id}/pushes/stats`);
export const getArticleRepliesPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/article/${id}/replies`);

/*
  User requests
*/
export const getUserInfoPromise = (id) => apiPublicRequestInstance.get(`/ptt/user/${id}`);
export const getUserLocationsPromise = (id) => apiRequestInstance.get(`/ptt/user/${id}/locations`);
export const getRelatedUserIDsPromise = (id) => apiRequestInstance.get(`/ptt/user/${id}/relatedIDs`);
export const getUserPushesBoardsBreakdownPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/user/${id}/pushes/boardsBreakdown`, {
    params: {
      since: dayjs()
        .subtract(DEFAULT_DAYS_AGO, 'd').format('YYYY-MM-DD'),
    },
  });
export const getUserPushesCategoriesBreakdownPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/user/${id}/pushes/categoriesBreakdown`, {
    params: {
      since: dayjs()
        .subtract(DEFAULT_DAYS_AGO, 'd').format('YYYY-MM-DD'),
    },
  });
export const getUserPushesStatsPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/user/${id}/pushes/stats`, {
    params: {
      since: dayjs()
        .subtract(DEFAULT_DAYS_AGO, 'd').format('YYYY-MM-DD'),
    },
  });
export const getUserRecentActivitiesPromise = (id) => apiPublicRequestInstance
  .get(`/ptt/user/${id}/activities`, {
    params: {
      since: dayjs()
        .subtract(DEFAULT_DAYS_AGO, 'd').format('YYYY-MM-DD'),
    },
  });
export const getUserDailyActivitiesDetailsPromise = (id, at) => apiPublicRequestInstance
  .get(`/ptt/user/${id}/dailyActivitiesDetails`, {
    params: { at },
  });
export const getUserArticlesPromise = (id, limit, offset) => apiPublicRequestInstance
  .get(`/ptt/user/${id}/articles`, {
    params: {
      limit,
      offset,
    },
  });
export const getUserFansPromise = (id, limit) => apiRequestInstance
  .get(`/ptt/user/${id}/fans`, {
    params: {
      limit,
    },
  });
